import React, { useEffect } from 'react';
import FileTabs from '../../components/FileTabs/FileTabs';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { checkIsLoaded, checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import {
  getMaritalStatusDictionary,
  getRegionDictionary,
  getSubwayStationDictionary,
} from '../../store/slices/dictionariesSlice/dictionariesSlice';
import { cleanPersonFullCard, getPersonFullCard } from '../../store/slices/personsSlice/personsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Spin, Row, Modal, message } from 'antd';
import { getProjects } from '../../store/slices/projectsSlice/projectsSlice';
import { ReactComponent as BackArrow } from '../../lib/img/back-arrow.svg';

function FileMainContainer() {
  const { fileId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { maritalStatusLoading, regionLoading, subwayStationLoading } = useAppSelector((state) => state.dictionaries);
  const { personFullCard, personFullCardLoading } = useAppSelector((state) => state.persons);
  const { projectsLoading } = useAppSelector((state) => state.projects);

  useEffect(() => {
    return () => {
      dispatch(cleanPersonFullCard());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!checkIsLoaded(maritalStatusLoading)) dispatch(getMaritalStatusDictionary());
    if (!checkIsLoaded(regionLoading)) dispatch(getRegionDictionary());
    if (!checkIsLoaded(subwayStationLoading)) dispatch(getSubwayStationDictionary());
    if (!checkIsLoaded(projectsLoading)) dispatch(getProjects({}));
  }, [dispatch]);

  const getPerson = async () => {
    if (fileId) {
      const resultAction = await dispatch(getPersonFullCard({ id: Number(fileId) }));

      // Изза того что Thank не отдавал ответ в catch, сделал проверку через сам Thank
      if (getPersonFullCard.rejected.match(resultAction) && resultAction.error?.message?.includes('403')) {
        Modal.error({
          content: (
            <div style={{ fontFamily: 'Roboto, sans-serif' }} className="font-size-14">
              У Вас нет прав для доступа к данной информации. <br />
              Обратитесь к администратору.
            </div>
          ),
          className: 'font-roboto password-forgotten-info-modal',
          width: '534px',
          icon: false,
          bodyStyle: {
            backgroundColor: '#F4F4F4',
            paddingTop: '20px',
            marginTop: '111px',
          },
          onOk: () => {
            message.destroy();
            Modal.destroyAll();
            navigate('/files/list');
          },
        });
      }
      if (getPersonFullCard.rejected.match(resultAction) && !resultAction.error?.message?.includes('403')) {
        message.destroy();
        message.error(
          {
            content: 'Ошибка загрузки анкеты: ' + resultAction.error?.message,
          },
          3
        );
      }
    }
  };

  useEffect(() => {
    getPerson();
  }, [dispatch]);

  const shouldRender = () => checkIsLoaded(maritalStatusLoading, regionLoading, subwayStationLoading, projectsLoading);

  const isVisiblePerson = personFullCard?.lastName || personFullCard?.firstName || personFullCard?.middleName;

  return (
    <Spin
      className="container_height"
      spinning={checkIsLoading(
        personFullCardLoading,
        projectsLoading,
        maritalStatusLoading,
        regionLoading,
        subwayStationLoading
      )}
    >
      {shouldRender() && (
        <>
          <div className="pl20 pr20">
            <Row>
              {isVisiblePerson && <BackArrow className="ml6 mt8 mr10 cursor-pointer" onClick={() => navigate('..')} />}
              <h2
                className="fullName"
                style={{
                  textDecoration: 'underline',
                  textDecorationColor: '#7590A3',
                  textUnderlineOffset: '7px',
                }}
              >{`${personFullCard?.lastName ? personFullCard?.lastName : ''} ${
                personFullCard?.firstName ? personFullCard?.firstName : ''
              } ${personFullCard?.middleName ? personFullCard?.middleName : ''}`}</h2>
            </Row>
            <FileTabs />
          </div>
          <Outlet />
        </>
      )}
    </Spin>
  );
}

export default FileMainContainer;
