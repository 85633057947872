import React from 'react';
import { Checkbox, Col, DatePicker, Divider, Form, Input, Row, Select, Radio } from 'antd';
import { replaceFalse } from './replaceFalse';
import { useAppSelector } from '../../store/hooks';
import useLabel from './useLabel';
import { displayFormat, toDisplayFormat } from '../../lib/utils/formatDate';
import moment from 'moment';
import { PersonTypes } from './PersonTypes';
import ProfileImage from '../ProfileImage/ProfileImage';
import { PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS } from '../../lib/const/permissions';
import usePermissions from '../../lib/utils/usePermissions';
import { fioValidator } from '../../lib/utils/fioValidator';
import { ReactComponent as ArrowDownFigIcon } from '../../lib/img/arrow-down-fig.svg';
import { fioLengthValidator } from '../../lib/utils/fioLengthValidator';
import { ReactComponent as InPrayListIcon } from '../../lib/img/in-pray-list-icon.svg';
import { datePikerMaskDefault } from '../../lib/const/masks';
import InputMask from 'react-input-mask';

const labelWidth = 180;

interface Props {
  isEditMode: boolean;
  isNewUser?: boolean;
  image?: any;
  handleNewImage: (file, route) => void;
  isImageLoading?: boolean;
}

function PersonalInfoBlock(props: Props) {
  const { isEditMode, isNewUser, image, handleNewImage, isImageLoading } = props;
  const { personFullCard } = useAppSelector((state) => state.persons);
  const { maritalStatus, personCategories } = useAppSelector((state) => state.dictionaries);
  const [hasAccess] = usePermissions();
  const [makeLabel] = useLabel();

  const showGender = (gender) => {
    switch (gender) {
      case 'M':
        return 'Мужской';
      case 'F':
        return 'Женский';
      default:
        return gender;
    }
  };

  return (
    <div className="block">
      <h3>Личные данные</h3>
      <Divider />
      <Row gutter={24}>
        {!isEditMode && !isNewUser && (
          <Col>
            <ProfileImage
              myProfile={false}
              image={image}
              handleNewImage={handleNewImage}
              isImageLoading={isImageLoading}
            />
          </Col>
        )}
        <Col span={isEditMode || isNewUser ? 24 : 17}>
          <Row gutter={24}>
            <Col span={isEditMode || isNewUser ? 16 : 12}>
              {isEditMode || isNewUser ? (
                <Form.Item
                  name="lastName"
                  label="Фамилия"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      validator: fioValidator,
                      message: 'Недопустимые символы',
                    },
                    {
                      validator: fioLengthValidator,
                      message: 'Максимум 64 символа',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : (
                <div className="info-row">
                  <div>{makeLabel('Фамилия', 120)}</div>
                  <div className="info">{`${personFullCard?.lastName}`}</div>
                </div>
              )}
            </Col>
            <Col span={isEditMode || isNewUser ? 8 : 12}>
              {isEditMode || isNewUser ? (
                <Form.Item
                  name="birthDate"
                  label="Дата рождения"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={(date) => {
                      if (isNewUser) return false;
                      if (personFullCard?.personTypeId === PersonTypes.Подопечный) return false;
                      if (moment().diff(date, 'years') < 18) return true;
                      return false;
                    }}
                    format={displayFormat}
                    inputRender={(props) => {
                      return <InputMask {...props} mask={datePikerMaskDefault} maskChar={null} />;
                    }}
                  />
                </Form.Item>
              ) : (
                <>
                  {makeLabel('Дата рождения', 150)}
                  {personFullCard?.birthDate ? `${toDisplayFormat(moment(personFullCard?.birthDate))}` : '-'}
                </>
              )}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={isEditMode || isNewUser ? 16 : 12}>
              {isEditMode || isNewUser ? (
                <Form.Item
                  name="firstName"
                  label="Имя"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      validator: fioValidator,
                      message: 'Недопустимые символы',
                    },
                    {
                      validator: fioLengthValidator,
                      message: 'Максимум 64 символа',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : (
                <div className="info-row">
                  <div>{makeLabel('Имя', 120)}</div>
                  <div className="info">{`${personFullCard?.firstName}`}</div>
                </div>
              )}
            </Col>
            <Col span={isEditMode || isNewUser ? 8 : 12}>
              {isEditMode || isNewUser ? (
                <Form.Item name="sex" label="Пол">
                  <Radio.Group>
                    <Radio value="M">М</Radio>
                    <Radio value="F">Ж</Radio>
                  </Radio.Group>
                </Form.Item>
              ) : (
                <>
                  {makeLabel('Пол', 150)}
                  {`${showGender(replaceFalse(personFullCard?.sex))}`}
                </>
              )}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={isEditMode || isNewUser ? 16 : 12}>
              <Form.Item
                shouldUpdate={(prevValues, currentValues) => prevValues?.noMiddleName !== currentValues?.noMiddleName}
              >
                {({ getFieldValue }) => {
                  if (isEditMode || isNewUser) {
                    return (
                      <Form.Item
                        name="middleName"
                        label="Отчество"
                        rules={[
                          {
                            required: !getFieldValue('noMiddleName'),
                          },
                          {
                            validator: !getFieldValue('noMiddleName') ? fioValidator : undefined,
                            message: 'Недопустимые символы',
                          },
                          {
                            validator: !getFieldValue('noMiddleName') ? fioLengthValidator : undefined,
                            message: 'Максимум 64 символа',
                          },
                        ]}
                      >
                        <Input disabled={getFieldValue('noMiddleName')} />
                      </Form.Item>
                    );
                  }
                  return (
                    <div className="info-row">
                      <div>{makeLabel('Отчество', 120)}</div>
                      <div className="info">{`${replaceFalse(personFullCard?.middleName)}`}</div>
                    </div>
                  );
                }}
              </Form.Item>
            </Col>
            {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && (
              <Col span={isEditMode || isNewUser ? 8 : 12}>
                {isEditMode || isNewUser ? (
                  <Form.Item name="deathDate" label="Дата смерти">
                    <DatePicker
                      format={displayFormat}
                      inputRender={(props) => {
                        return <InputMask {...props} mask={datePikerMaskDefault} maskChar={null} />;
                      }}
                    />
                  </Form.Item>
                ) : (
                  <>
                    {makeLabel('Дата смерти', 150)}
                    {personFullCard?.deathDate ? `${toDisplayFormat(moment(personFullCard?.deathDate))}` : '-'}
                  </>
                )}
              </Col>
            )}
          </Row>
          {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && (
            <Row gutter={24}>
              <Col span={isEditMode || isNewUser ? 16 : 0}>
                <Form.Item valuePropName="checked" name="noMiddleName">
                  <Checkbox>Нет отчества</Checkbox>
                </Form.Item>
              </Col>
              <Col span={isEditMode || isNewUser ? 8 : 24}>
                {isEditMode || isNewUser ? (
                  <Form.Item valuePropName="checked" name="hasCar">
                    <Checkbox>Наличие авто</Checkbox>
                  </Form.Item>
                ) : (
                  <>
                    {makeLabel('Наличие авто', labelWidth)}
                    {`${replaceFalse(personFullCard?.hasCar, true)}`}
                  </>
                )}
              </Col>
            </Row>
          )}
          {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && (
            <Row gutter={24}>
              <Col>
                {isEditMode || isNewUser ? (
                  <Form.Item name="maritalStatusId" label="Семейное положение" className="card-select">
                    <Select suffixIcon={<ArrowDownFigIcon />}>
                      {maritalStatus
                        .filter((el) => !el.deleted)
                        .map((status) => (
                          <Select.Option key={status.id} value={status.id}>
                            {status.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <>
                    {makeLabel('Семейное положение', labelWidth)}
                    {`${replaceFalse(
                      maritalStatus.find((status) => status.id === personFullCard?.maritalStatusId)?.name
                    )}`}
                  </>
                )}
              </Col>
            </Row>
          )}
          {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && (
            <Row gutter={24}>
              <Col>
                {isEditMode || isNewUser ? (
                  <Form.Item name="partnerFio" label="ФИО супруга/супруги" className="card-select">
                    <Input />
                  </Form.Item>
                ) : (
                  <>
                    {makeLabel('ФИО супруга/супруги', labelWidth)}
                    {`${replaceFalse(personFullCard?.partnerFio)}`}
                  </>
                )}
              </Col>
            </Row>
          )}
          {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) &&
            (personFullCard?.personTypeId === PersonTypes.Подопечный || isNewUser) && (
              <Row gutter={24}>
                <Col>
                  {isEditMode || isNewUser ? (
                    <Form.Item name="personCategory" label="Категория" className="card-select">
                      <Select mode="multiple" suffixIcon={<ArrowDownFigIcon />} showArrow listHeight={239}>
                        {personCategories
                          .filter((el) => !el.deleted)
                          .map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  ) : (
                    <div className="row-value-ellipsis">
                      {makeLabel('Категория', labelWidth)}
                      {`${replaceFalse(
                        personFullCard?.personCategory &&
                          personFullCard?.personCategory
                            .map((item) => {
                              return personCategories.find((category) => category.id === item)?.name;
                            })
                            .join(', ')
                      )}`}
                    </div>
                  )}
                </Col>
              </Row>
            )}
          {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && (
            <Row gutter={24}>
              <Col>
                {isEditMode || isNewUser ? (
                  <Form.Item valuePropName="checked" name="hasChildren" label="Информация о детях">
                    <Checkbox>Есть дети</Checkbox>
                  </Form.Item>
                ) : (
                  <>
                    {makeLabel('Информация о детях', labelWidth)}
                    {`${replaceFalse(personFullCard?.hasChildren, true)}`}
                  </>
                )}
              </Col>
            </Row>
          )}
          {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && (
            <Row gutter={24}>
              <Col>
                {isEditMode || isNewUser ? (
                  <Form.Item valuePropName="checked" name="inPrayList">
                    <Checkbox>В списке для молитв</Checkbox>
                  </Form.Item>
                ) : (
                  <Row>
                    {makeLabel('В списке молитв', labelWidth)}
                    {`${replaceFalse(personFullCard?.inPrayList, true)}`}
                    {personFullCard?.inPrayList ? <InPrayListIcon className="ml5" /> : null}
                  </Row>
                )}
              </Col>
            </Row>
          )}
          {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && (
            <Row gutter={24}>
              <Col>
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) => prevValues?.inPrayList !== currentValues?.inPrayList}
                >
                  {({ getFieldValue }) => {
                    if (isEditMode || isNewUser) {
                      return (
                        <Form.Item name="prayListReason" label="Причина добавления в список для молитв">
                          <Input disabled={!getFieldValue('inPrayList')} />
                        </Form.Item>
                      );
                    } else if (getFieldValue('inPrayList')) {
                      return (
                        <div>
                          <div>{makeLabel('Причина добавления в список для молитв', 338)}</div>
                          <div className="info">{`${replaceFalse(personFullCard?.prayListReason)}`}</div>
                        </div>
                      );
                    }
                  }}
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default PersonalInfoBlock;
