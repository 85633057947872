import React, { FC } from 'react';
import { Row, Col, Form, Select, Button, Checkbox } from 'antd';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useMainContext } from '../../containers/MainContainer/MainContext';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import selectFilterOption, { selectFilterOptionIcon } from '../../lib/utils/selectFilterOption';
import './MapSearchApplications.less';
import { ApplicationsMapSearchType } from '../../lib/interfaces/SearchApplicationsBody';
import { fetchPersonsList, cleanPersonsList } from '../../store/slices/applicationsSlice/applicationsSlice';

interface IProps {
  mapSearchType: ApplicationsMapSearchType;
}

export const MapSearchApplications: FC<IProps> = ({ mapSearchType }) => {
  const dispatch = useAppDispatch();

  const { personCategory, appSubject, region, personStatuses } = useAppSelector((state) => state.dictionaries);
  const { projects, projectsLoading } = useAppSelector((state) => state.projects);
  const { hasAccess, searchApplicationsState, findDynamicSVGReactElement } = useMainContext();

  const onChangeRegionId = () => {
    searchApplicationsState.handleSetSearchFormFields({
      projectIds: [],
    });
  };

  const onFinish = () => {
    searchApplicationsState.getApplicationsList();
  };

  const onReset = () => {
    searchApplicationsState.resetSearchFormFields();
    dispatch(cleanPersonsList());
  };

  return (
    <Form
      fields={searchApplicationsState.searchFormFields}
      onValuesChange={searchApplicationsState.handleSetSearchFormFields}
      onFinish={onFinish}
      className="ml20 mr20 mb10 applications-map-search"
    >
      <Row gutter={20} justify="space-between" style={{ width: '100%' }} wrap={false}>
        <Col style={{ width: '100%' }}>
          <Row gutter={20}>
            <Col span={3}>
              <Form.Item name="regionId">
                <Select
                  placeholder="Субъект РФ"
                  onSelect={onChangeRegionId}
                  allowClear
                  onClear={onChangeRegionId}
                  disabled={!hasAccess(['APPLICATIONS.READ.ALLREGIONS'])}
                >
                  {region.map(({ id, name }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item dependencies={['regionId', 'projectIds']} className="form-item-dependencies">
                {() => {
                  const regionId = searchApplicationsState.getSearchFormFieldValue('regionId');
                  const projectIds = searchApplicationsState.getSearchFormFieldValue('projectIds');
                  // Вывод отображения всех выбранных проектов
                  const getProjectTitle = () => {
                    if (!regionId) return 'Необходимо выбрать субъект РФ';
                    if (!Array.isArray(projects)) return 'Нет доступных проектов';
                    return projectIds
                      .map((projectId) => projects.find((item) => item.id === projectId)?.title)
                      .join('\n');
                  };

                  return (
                    <div title={getProjectTitle()}>
                      <Form.Item name="projectIds">
                        <Select
                          placeholder={projects.length === 0 ? 'Нет доступных проектов' : 'Проект'}
                          allowClear
                          mode="multiple"
                          maxTagCount={2}
                          maxTagTextLength={15}
                          disabled={!regionId || projects.length === 0}
                          loading={checkIsLoading(projectsLoading)}
                          showSearch
                          filterOption={selectFilterOption}
                          showArrow={true}
                        >
                          {Array.isArray(projects) &&
                            projects
                              .filter((project) => project.regionId === regionId)
                              .map(({ id, title }) => (
                                <Select.Option key={id} value={id}>
                                  {title}
                                </Select.Option>
                              ))}
                        </Select>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="subjectId">
                <Select placeholder="Вид помощи" allowClear showSearch filterOption={selectFilterOptionIcon}>
                  {Array.isArray(appSubject) &&
                    [...appSubject].map(({ id, name }) => (
                      <Select.Option key={id} value={id}>
                        <div
                          className="display-flex"
                          style={{ justifyContent: 'center', alignItems: 'center' }}
                          title={name}
                        >
                          <div
                            className="display-flex icon-application-select"
                            style={{ alignItems: 'center', minWidth: 20, maxWidth: 20 }}
                          >
                            {findDynamicSVGReactElement('app_subject', 'help', 'iconName', id)}
                          </div>
                          <span className="ml5 ant-select-item-option-content">{name}</span>
                        </div>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="personCategoryIds">
                <Select
                  placeholder="Категория"
                  allowClear
                  mode="multiple"
                  maxTagCount={2}
                  maxTagTextLength={15}
                  showSearch
                  filterOption={selectFilterOption}
                  showArrow={true}
                >
                  {personCategory.map(({ id, name }) => (
                    <Select.Option key={id} value={id}>
                      <span>{name}</span>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {mapSearchType === 'MAP_ALL' && hasAccess(['APPLICATION.PERSON.MAP']) && (
              <>
                <Col>
                  <Form.Item name="isVisiblePerson" valuePropName="checked">
                    <Checkbox
                      onChange={(e) => {
                        searchApplicationsState.handleSetSearchFormFields({
                          isVisiblePerson: e.target.checked,
                        });
                      }}
                      checked={searchApplicationsState.getSearchFormFieldValue('isVisiblePerson')}
                    >
                      Показать добровольцев
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Form.Item noStyle dependencies={['isVisiblePerson']}>
                    {({ getFieldValue }) => {
                      const isVisiblePerson = getFieldValue('isVisiblePerson');

                      return (
                        <Form.Item name="personStatusIds">
                          <Select
                            placeholder="Статус добровольца"
                            allowClear
                            mode="multiple"
                            maxTagCount={2}
                            maxTagTextLength={15}
                            showSearch
                            filterOption={selectFilterOption}
                            showArrow={true}
                            disabled={!isVisiblePerson}
                          >
                            {personStatuses
                              .filter(({ id }) => id === 3 || id === 4)
                              .map(({ id, name }) => (
                                <Select.Option key={id} value={id}>
                                  <span>{name}</span>
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Form.Item noStyle dependencies={['regionId', 'isVisiblePerson']}>
                    {({ getFieldValue }) => {
                      const regionId = getFieldValue('regionId');
                      const isVisiblePerson = getFieldValue('isVisiblePerson');

                      return (
                        <Form.Item name="projectVolunteerIds">
                          <Select
                            placeholder="Закреплён за проектом"
                            allowClear
                            mode="multiple"
                            maxTagCount={2}
                            maxTagTextLength={15}
                            showSearch
                            filterOption={selectFilterOption}
                            showArrow={true}
                            disabled={!isVisiblePerson}
                          >
                            {projects.map((proj) => {
                              if (proj.regionId === regionId) {
                                return <Select.Option key={proj.id}>{proj.title}</Select.Option>;
                              }
                              return null;
                            })}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Col>
        <Col style={{ marginLeft: '-10px' }}>
          <Row gutter={20} wrap={false}>
            <Col className="ml-auto">
              <Button onClick={onReset} className="btn-search">
                Сбросить
              </Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" className="btn-search">
                Применить
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
