import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import requestStatusCodes from '../../../lib/const/requestStatusCodes';
import { RequestStatuses } from '../../../lib/types/RequestStatuses';
import { SearchParams } from '../../../lib/interfaces/SearchParams';
import { SearchApplicationsBody } from '../../../lib/interfaces/SearchApplicationsBody';
import { Application } from '../../../lib/interfaces/Application';
import {
  fetchApplicationCommentsRoute,
  fetchApplicationFilesListRoute,
  fetchApplicationHistoryRoute,
  fetchApplicationResponseHistoryRoute,
  fetchApplicationIconsRoute,
  fetchApplicationRoute,
  fetchExecutorsRoute,
  searchApplicationsListRoute,
  searchPersonsListRoute,
} from '../../../lib/routes/applications';
import { SearchResult } from '../../../lib/interfaces/SearchResult';
import defaultSearchResult from '../../../lib/const/defaultSearchResult';
import { ApplicationFileData } from '../../../lib/interfaces/ApplicationFileData';
import { Comment } from '../../../lib/interfaces/Comment';
import { ExecutorsSearchResult } from '../../../lib/interfaces/Executors';
import { ApplicationHistoryItem } from '../../../lib/interfaces/ApplicationHistoryItem';
import { ApplicationResponseHistory } from '../../../lib/interfaces/ApplicationResponseHistory';
import { ApplicationCopy } from '../../../lib/interfaces/ApplicationCopy';
import { ApplicationIconsItem } from '../../../lib/interfaces/ApplicationIconsItem';
import { Modal } from 'antd';
import { SearchPersonsBody } from '../../../lib/interfaces/SearchPersonsBody';
import { Person } from '../../../lib/interfaces/Person';

interface ApplicationsSearchResult extends SearchResult {
  content: Application[];
}

interface InitialState {
  applicationsList: ApplicationsSearchResult;
  applicationsListLoading: RequestStatuses;
  personsList: Person[];
  personsListLoading: RequestStatuses;
  application: Application | undefined;
  applicationLoading: RequestStatuses;
  applicationFilesList: ApplicationFileData[];
  applicationFilesListLoading: RequestStatuses;
  applicationComments: Comment[];
  applicationCommentsLoading: RequestStatuses;
  executors: ExecutorsSearchResult;
  executorsLoading: RequestStatuses;
  applicationHistory: ApplicationHistoryItem[];
  applicationHistoryLoading: RequestStatuses;
  applicationResponseHistory: ApplicationResponseHistory;
  applicationResponseHistoryLoading: RequestStatuses;
  applicationCopy: ApplicationCopy | undefined;
  applicationSaved: ApplicationCopy | undefined;
  applicationIconsLoading: RequestStatuses;
  applicationIcons: ApplicationIconsItem[];
  applicationsMyCheckLoading: RequestStatuses;
  applicationsMyCheck: boolean;
}

const initialState: InitialState = {
  applicationsList: { ...defaultSearchResult, content: [{ id: 0 }] },
  applicationsListLoading: requestStatusCodes.idle,
  personsList: [],
  personsListLoading: requestStatusCodes.idle,
  application: undefined,
  applicationLoading: requestStatusCodes.idle,
  applicationFilesList: [],
  applicationFilesListLoading: requestStatusCodes.idle,
  applicationComments: [],
  applicationCommentsLoading: requestStatusCodes.idle,
  executors: {
    allExecutors: [],
    chosenExecutors: [],
    potentialExecutors: [],
  },
  executorsLoading: requestStatusCodes.idle,
  applicationHistory: [],
  applicationHistoryLoading: requestStatusCodes.idle,
  applicationResponseHistory: {
    chosenExecutors: [],
    potentialExecutors: [],
    refusedExecutors: [],
  },
  applicationResponseHistoryLoading: requestStatusCodes.idle,

  applicationCopy: undefined,
  applicationSaved: undefined,
  applicationIconsLoading: requestStatusCodes.idle,
  applicationIcons: [],

  applicationsMyCheckLoading: requestStatusCodes.idle,
  applicationsMyCheck: false,
};

export const fetchApplicationsList = createAsyncThunk(
  'applications/fetchApplicationsList',
  async ({ searchParams, searchBody }: { searchParams: SearchParams; searchBody: SearchApplicationsBody }) => {
    const response = await axios.post(searchApplicationsListRoute(searchParams), searchBody);
    const { data } = response;

    if (data?.content && Array.isArray(data.content) && data?.content.length === 0) {
      Modal.info({ title: 'Нет данных', centered: true });
    }

    return data;
  }
);

export const fetchPersonsList = createAsyncThunk(
  'applications/fetchPersonsList',
  async ({ searchParams, searchBody }: { searchParams: SearchParams; searchBody: SearchPersonsBody }) => {
    try {
      const response = await axios.post(searchPersonsListRoute(searchParams), searchBody);
      const { data } = await response;
      // if (data?.content && Array.isArray(data.content) && data?.content.length === 0) {
      //   Modal.info({ title: 'Нет данных', centered: true });
      // }
      return data?.content;
    } catch (e: any) {
      Modal.info({ title: 'Добровольцы не отображены', centered: true, content: e.response?.data?.message });
      return [];
    }
  }
);

export const fetchApplication = createAsyncThunk(
  'applications/fetchApplication',
  async ({ applicationId }: { applicationId: string }): Promise<Application> => {
    try {
      const response = await axios.get(fetchApplicationRoute({ applicationId }));
      const { data } = response;
      return data;
    } catch (e: any) {
      throw new Error(JSON.stringify(e.response));
    }
  }
);

export const fetchApplicationFilesList = createAsyncThunk(
  'applications/fetchApplicationFilesList',
  async ({ applicationId }: { applicationId: string }): Promise<ApplicationFileData[]> => {
    try {
      const response = await axios.get(fetchApplicationFilesListRoute({ applicationId }));
      const { data } = response;
      return data;
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const fetchApplicationComments = createAsyncThunk(
  'applications/fetchApplicationComments',
  async ({ applicationId }: { applicationId: string }) => {
    const response = await axios.get(fetchApplicationCommentsRoute({ applicationId }));
    const { data } = response;
    return data;
  }
);

export const fetchExecutors = createAsyncThunk(
  'applications/fetchExecutors',
  async ({ applicationId }: { applicationId: string }) => {
    const response = await axios.get(fetchExecutorsRoute({ applicationId }));
    const { data } = response;
    return data;
  }
);

export const fetchApplicationHistory = createAsyncThunk(
  'applications/fetchApplicationHistory',
  async ({ applicationId }: { applicationId: string }) => {
    const response = await axios.get(fetchApplicationHistoryRoute({ applicationId }));
    const { data } = response;
    return data;
  }
);

export const fetchApplicationResponseHistory = createAsyncThunk(
  'applications/fetchApplicationResponseHistory',
  async ({ applicationId }: { applicationId: string }) => {
    const response = await axios.get(fetchApplicationResponseHistoryRoute({ applicationId }));
    const { data } = response;
    return data;
  }
);

export const fetchApplicationIcons = createAsyncThunk(
  'applications/fetchApplicationIcons',
  async ({ dicType }: { dicType: string }) => {
    const response = await axios.get(fetchApplicationIconsRoute({ dicType }));
    const { data } = response;
    return data;
  }
);

export const fetchApplicationsMyCheck = createAsyncThunk(
  'applications/fetchApplicationsMyCheck',
  async ({ searchParams, searchBody }: { searchParams: SearchParams; searchBody: SearchApplicationsBody }) => {
    const response = await axios.post(searchApplicationsListRoute(searchParams), searchBody);
    const { data } = response;
    return data;
  }
);

const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    cleanApplicationCard: (state) => {
      state.applicationLoading = requestStatusCodes.idle;
      state.application = undefined;
    },
    cleanPersonsList: (state) => {
      state.personsListLoading = requestStatusCodes.idle;
      state.personsList = [];
    },
    setApplicationCopy: (state, action) => {
      state.applicationCopy = action.payload;
    },
    setApplicationSaved: (state, action) => {
      state.applicationSaved = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchApplicationsList.pending, (state) => {
        state.applicationsListLoading = requestStatusCodes.pending;
      })
      .addCase(fetchApplicationsList.rejected, (state) => {
        state.applicationsListLoading = requestStatusCodes.rejected;
      })
      .addCase(fetchApplicationsList.fulfilled, (state, { payload }) => {
        state.applicationsListLoading = requestStatusCodes.fulfilled;
        state.applicationsList = payload;
      })
      .addCase(fetchPersonsList.pending, (state) => {
        state.personsListLoading = requestStatusCodes.pending;
      })
      .addCase(fetchPersonsList.rejected, (state) => {
        state.personsListLoading = requestStatusCodes.rejected;
      })
      .addCase(fetchPersonsList.fulfilled, (state, { payload }) => {
        state.personsListLoading = requestStatusCodes.fulfilled;
        state.personsList = payload;
      })
      .addCase(fetchApplication.pending, (state) => {
        state.applicationLoading = requestStatusCodes.pending;
      })
      .addCase(fetchApplication.rejected, (state) => {
        state.applicationLoading = requestStatusCodes.rejected;
      })
      .addCase(fetchApplication.fulfilled, (state, { payload }) => {
        state.applicationLoading = requestStatusCodes.fulfilled;
        state.application = payload;
      })
      .addCase(fetchApplicationFilesList.pending, (state) => {
        state.applicationFilesListLoading = requestStatusCodes.pending;
      })
      .addCase(fetchApplicationFilesList.rejected, (state) => {
        state.applicationFilesListLoading = requestStatusCodes.rejected;
      })
      .addCase(fetchApplicationFilesList.fulfilled, (state, { payload }) => {
        state.applicationFilesListLoading = requestStatusCodes.fulfilled;
        state.applicationFilesList = payload;
      })
      .addCase(fetchApplicationComments.pending, (state) => {
        state.applicationCommentsLoading = requestStatusCodes.pending;
      })
      .addCase(fetchApplicationComments.rejected, (state) => {
        state.applicationCommentsLoading = requestStatusCodes.rejected;
      })
      .addCase(fetchApplicationComments.fulfilled, (state, { payload }) => {
        state.applicationCommentsLoading = requestStatusCodes.fulfilled;
        state.applicationComments = payload.comments;
      })
      .addCase(fetchExecutors.pending, (state) => {
        state.executorsLoading = requestStatusCodes.pending;
      })
      .addCase(fetchExecutors.rejected, (state) => {
        state.executorsLoading = requestStatusCodes.rejected;
      })
      .addCase(fetchExecutors.fulfilled, (state, { payload }) => {
        state.executorsLoading = requestStatusCodes.fulfilled;
        state.executors = payload;
      })
      .addCase(fetchApplicationHistory.pending, (state) => {
        state.applicationHistoryLoading = requestStatusCodes.pending;
      })
      .addCase(fetchApplicationHistory.rejected, (state) => {
        state.applicationHistoryLoading = requestStatusCodes.rejected;
      })
      .addCase(fetchApplicationHistory.fulfilled, (state, { payload }) => {
        state.applicationHistoryLoading = requestStatusCodes.fulfilled;
        state.applicationHistory = payload;
      })
      .addCase(fetchApplicationResponseHistory.pending, (state) => {
        state.applicationResponseHistoryLoading = requestStatusCodes.pending;
      })
      .addCase(fetchApplicationResponseHistory.rejected, (state) => {
        state.applicationResponseHistoryLoading = requestStatusCodes.rejected;
      })
      .addCase(fetchApplicationResponseHistory.fulfilled, (state, { payload }) => {
        state.applicationResponseHistoryLoading = requestStatusCodes.fulfilled;
        state.applicationResponseHistory = payload;
      })
      .addCase(fetchApplicationIcons.pending, (state) => {
        state.applicationIconsLoading = requestStatusCodes.pending;
      })
      .addCase(fetchApplicationIcons.rejected, (state) => {
        state.applicationIconsLoading = requestStatusCodes.rejected;
      })
      .addCase(fetchApplicationIcons.fulfilled, (state, { payload }) => {
        state.applicationIconsLoading = requestStatusCodes.fulfilled;
        state.applicationIcons = payload;
      })
      .addCase(fetchApplicationsMyCheck.pending, (state) => {
        state.applicationsMyCheckLoading = requestStatusCodes.pending;
      })
      .addCase(fetchApplicationsMyCheck.rejected, (state) => {
        state.applicationsMyCheckLoading = requestStatusCodes.rejected;
      })
      .addCase(fetchApplicationsMyCheck.fulfilled, (state, { payload }) => {
        state.applicationsMyCheckLoading = requestStatusCodes.fulfilled;

        state.applicationsMyCheck = payload?.content?.length > 0 || false;
      });
  },
});

const { reducer, actions } = applicationsSlice;
const { cleanApplicationCard, setApplicationCopy, setApplicationSaved, cleanPersonsList } = actions;

export { reducer, cleanApplicationCard, setApplicationCopy, setApplicationSaved, cleanPersonsList };
