import React, { useEffect, useState } from 'react';
import FileCard from '../../components/FileCard/FileCard';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { toHttpFormatEndOfDay, toYearMonthDayLineFormat } from '../../lib/utils/formatDate';
import axios from 'axios';
import { editPersonRoute, getPersonPhotoRoute } from '../../lib/routes/persons';
import { getPersonFiles, getPersonFullCard } from '../../store/slices/personsSlice/personsSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { message, Modal, Spin } from 'antd';
import { checkIsLoaded } from '../../lib/utils/checkLoadingStatus';
import { FileCardContainerContext } from './FileCardContainerContext';
import {
  getAwarenessSourceDictionary,
  getContractStatusesDictionary,
  getEducationDictionary,
  getPersonCategoryDictionary,
  getPersonStatusesDictionary,
  getPersonTypesDictionary,
} from '../../store/slices/dictionariesSlice/dictionariesSlice';
import { getProjects } from '../../store/slices/projectsSlice/projectsSlice';
import { useDictionaries } from '../../lib/utils/useDictionaries';
import moment from 'moment';

function FileCardContainer() {
  const { fileId } = useParams();
  const { personFullCard, personFullCardLoading } = useAppSelector((state) => state.persons);
  const {
    educationLoading,
    personTypesLoading,
    personCategoriesLoading,
    personStatusesLoading,
    awarenessSourceLoading,
    phoneTypeLoading,
    contractStatusesLoading,
  } = useAppSelector((state) => state.dictionaries);
  const { projectsLoading } = useAppSelector((state) => state.projects);
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useAppDispatch();
  const [image, setImage] = useState<any>();
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);
  const { loadDictionaries } = useDictionaries();
  const navigate = useNavigate();

  const getPhoto = () => {
    setIsImageLoading(true);
    axios
      .get(getPersonPhotoRoute(personFullCard?.id), {
        responseType: 'arraybuffer',
      })
      .then((res) => {
        setImage(Buffer.from(res.data, 'binary').toString('base64'));
      })
      .finally(() => {
        setIsImageLoading(false);
      });
  };

  const handleNewPhoto = (file, route) => {
    axios
      .post(
        route(),
        { file },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then(() => {
        getPhoto();
      });
  };

  useEffect(() => {
    if (personFullCard) {
      getPhoto();
    }
  }, [personFullCard]);

  // useEffect(() => {
  //   if (fileId) {
  //     dispatch(getPersonPhoto({personId: fileId}))
  //   }
  // }, [])

  useEffect(() => {
    loadDictionaries(['phone_type']);
    if (!checkIsLoaded(educationLoading)) dispatch(getEducationDictionary());
    if (!checkIsLoaded(projectsLoading)) dispatch(getProjects({}));
    if (!checkIsLoaded(personTypesLoading)) dispatch(getPersonTypesDictionary());
    if (!checkIsLoaded(personCategoriesLoading)) dispatch(getPersonCategoryDictionary());
    if (!checkIsLoaded(personStatusesLoading)) dispatch(getPersonStatusesDictionary());
    if (!checkIsLoaded(awarenessSourceLoading)) dispatch(getAwarenessSourceDictionary());
    if (!checkIsLoaded(awarenessSourceLoading)) dispatch(getAwarenessSourceDictionary());
    if (!checkIsLoaded(contractStatusesLoading)) dispatch(getContractStatusesDictionary());
  }, []);

  const getPersonFilesFunc = async () => {
    if (fileId) {
      const resultAction = await dispatch(getPersonFiles({ personId: fileId }));

      // Изза того что Thank не отдавал ответ в catch, сделал проверку через сам Thank
      //   if (getPersonFiles.rejected.match(resultAction) && resultAction.error?.message?.includes('403')) {
      //     Modal.error({
      //       content: (
      //         <div style={{ fontFamily: 'Roboto, sans-serif' }} className="font-size-14">
      //           У Вас нет прав для доступа к данной информации. <br />
      //           Обратитесь к администратору.
      //         </div>
      //       ),
      //       className: 'font-roboto password-forgotten-info-modal',
      //       width: '534px',
      //       icon: false,
      //       bodyStyle: {
      //         backgroundColor: '#F4F4F4',
      //         paddingTop: '20px',
      //         marginTop: '111px',
      //       },
      //       onOk: () => {
      //         message.destroy();
      //         Modal.destroyAll();
      //         navigate('/applications/list');
      //       },
      //     });
      //   }
      //   if (getPersonFiles.rejected.match(resultAction) && !resultAction.error?.message?.includes('403')) {
      //     message.destroy();
      //     message.error(
      //       {
      //         content: 'Ошибка загрузки файлов анкеты: ' + resultAction.error?.message,
      //       },
      //       3
      //     );
      //   }
    }
  };

  useEffect(() => {
    getPersonFilesFunc();
  }, [dispatch]);

  const editPerson = (fields) => {
    Object.keys(fields).forEach((key) => fields[key] === undefined && delete fields[key]);

    const newWardData: any = {
      ...personFullCard,
      personCategory: fields.personCategory,
      birthDate: toYearMonthDayLineFormat(fields?.birthDate),
      deathDate: fields?.deathDate && toYearMonthDayLineFormat(fields?.deathDate),
      sex: fields.sex,
      firstName: fields.firstName,
      middleName: fields.noMiddleName ? '' : fields.middleName,
      lastName: fields.lastName,
      personStatusId: fields.personStatusId,
      maritalStatusId: fields.maritalStatusId,
      regionId: fields.regionId,
      hasChildren: fields.hasChildren,
      inPrayList: fields.inPrayList,
      hasCar: fields.hasCar,
      prayListReason: fields.prayListReason,
      partnerFio: fields.partnerFio,
      birthPlace: fields.birthPlace,
      dsc: fields.dsc,
      email: fields.email?.toLowerCase(),
      address: {
        ...personFullCard?.address,
        subwayStationId: fields.subwayStationId,
        entrance: fields.entrance,
        floor: fields.floor,
        intercomCode: fields.intercomCode,
        apartment: fields.apartment,
        unstructuredAddress: fields.unstructuredAddress,
        dsc: fields.dsc,
      },
      education: {
        ...personFullCard?.education,
        educationId: fields.educationId,
        institution: fields.institution,
        addEducation: fields.addEducation,
        profession: fields.profession,
        workPlace: fields.workPlace,
      },
      health: {
        ...personFullCard?.health,
        chronicDiseases: fields.chronicDiseases,
        disabledReason: fields.disabledReason,
        healthRestriction: fields.healthRestriction,
        hasMedicalBook: fields.hasMedicalBook,
      },
      official: {
        ...personFullCard?.official,
        volunteerReason: fields.volunteerReason,
        awarenessSource: fields.awarenessSource,
        otherSource: fields.otherSource,
        coordFio: fields.coordFio,
        coordComment: fields.coordComment,
        priestFio: fields.priestFio,
        priestComment: fields.priestComment,
        interviewerFio: fields.interviewerFio,
        interviewDate: fields.interviewDate,
        trainingDate: fields.trainingDate,
        passedTraining: fields.passedTraining,
        mmoWard: fields.mmoWard,
        constWard: fields.constWard,
      },
      social: {
        ...personFullCard?.social,
        floorCovering: fields.floorCovering,
        bathroom: fields.bathroom,
        homeDress: fields.homeDress,
        sleeper: fields.sleeper,
        isRegistered: fields.isRegistered,
        needsProcedures: fields.needsProcedures,
        needsCooking: fields.needsCooking,
        needsCleaning: fields.needsCleaning,
        apartMovements: fields.apartMovements,
        cityMovements: fields.cityMovements,
        hasSocialWorker: fields.hasSocialWorker,
        socialWorkerFio: fields.socialWorkerFio,
        partnerInfo: fields.partnerInfo,
        commonView: fields.commonView,
        straightBed: fields.straightBed,
        livingAlone: fields.livingAlone,
        goingOut: fields.goingOut,
      },
      religion: {
        ...personFullCard?.religion,
        personId: fields.personId,
        religion: fields.religion,
        church: fields.church,
        sacramentReason: fields.sacramentReason,
        baptismalName: fields.baptismalName,
        nameDays: fields.nameDays,
        needsPriest: fields.needsPriest,
        blessed: fields.blessed,
      },
      phones: fields.phones,
      contract: {
        id: personFullCard?.contract?.id,
        statusId: fields.statusId,
        endDate: moment(fields.endDate).endOf('day').format('YYYY-MM-DD'),
      },
    };

    axios
      .patch(editPersonRoute(personFullCard?.id), newWardData)
      .then(() => {
        dispatch(getPersonFullCard({ id: Number(fileId) }));
        setIsEditMode(false);
        message.success('Изменения сохранены');
      })
      .catch((e) => {
        Modal.error({
          title: <div>При редактировании анкеты возникла ошибка.</div>,
          content: (
            <>
              <div>Пожалуйста, повторите попытку позднее.</div>
            </>
          ),
          bodyStyle: { width: '600px', backgroundColor: '#F4F4F4' },
        });
      });
  };

  return (
    <FileCardContainerContext.Provider value={{ isEditMode }}>
      <Spin
        spinning={
          !checkIsLoaded(
            awarenessSourceLoading,
            phoneTypeLoading,
            personFullCardLoading,
            personCategoriesLoading,
            educationLoading,
            projectsLoading,
            personTypesLoading,
            personStatusesLoading
          )
        }
      >
        {checkIsLoaded(
          awarenessSourceLoading,
          personFullCardLoading,
          phoneTypeLoading,
          educationLoading,
          projectsLoading,
          personTypesLoading,
          personCategoriesLoading,
          personStatusesLoading
        ) && (
          <FileCard
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            editPerson={editPerson}
            image={image}
            handleNewImage={handleNewPhoto}
            isImageLoading={isImageLoading}
          />
        )}
      </Spin>
    </FileCardContainerContext.Provider>
  );
}

export default FileCardContainer;
